<template>
  <div class="home">
    <mobile-top-bar />
    <mobile-desc style="margin-top: 40px" />
    <mobile-preview style="margin-top: 40px" />
  </div>
</template>

<script>
import MobileDesc from "@/components/MobileDesc";
import MobileTopBar from "@/components/MobileTopBar";
import MobilePreview from "@/components/MobilePreview";
export default {
  name: "Mobile",
  components: { MobilePreview, MobileTopBar, MobileDesc },
  props: {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  background: url("../assets/bg_m.jpg");
  background-size: cover;
  left: 0;
  top: 0;
  overflow: hidden;
}
</style>
