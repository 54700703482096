<template>
  <div
    style="
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-top: 20px;
      top: 0;
      left: 0;
      right: 0;
    "
  >
    <el-image
      :src="require('@/assets/logo.svg')"
      style="height: 20px; width: 56px; margin-left: 10px"
    />
    <div style="display: flex; align-items: center; margin-right: 10px">
      <label
        style="
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC, serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        "
        >联系我们：0579-82923510</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileTopBar",
  props: {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
