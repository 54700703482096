<template>
  <div style="display: flex; flex-direction: column; padding: 0 30px">
    <label
      style="
        font-size: 40px;
        font-family: PingFangSC-Semisolid, PingFang SC, serif;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 20px;
      "
      >肥猫商城</label
    >
    <label
      style="
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC, sans-serif;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 20px;
      "
      >品质保证 吃遍全国</label
    >
    <label
      style="
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC, sans-serif;
        font-weight: 400;
        color: #ffffff;
      "
      >这里是肥猫官网<br />
      肥猫创立至2021年是第9个年头，“品质保证、吃遍全国”是肥猫的宗旨！<br />
      肥猫的用户群体以70、80、90后宝妈为主！<br />
      在产品的定位上，肥猫专注健康又好吃的标签<br />
      给广大的用户提供放心的美食！</label
    >
    <div style="margin-top: 20px">
      <el-button @click="linkOpen('https://wap.feelmao.com/app-downloads')"
        >下载APP</el-button
      >
      <el-button
        plain
        style="
          background: transparent;
          color: white;
          border-color: white;
          margin-left: 15px;
        "
        @click="linkOpen('https://wap.feelmao.com/')"
        >使用网页端</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileDesc",
  props: {},
  data() {
    return {};
  },
  watch: {},
  methods: {
    linkOpen(url) {
      window.open(url, "_blank"); // 新窗口打开外链接
    },
  },
};
</script>

<style lang="scss" scoped></style>
